import VueI18n from "vue-i18n";
import Vue from "vue";
import id from "./locales/id.json";
import en from "./locales/en.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "id",
  fallbackLocale: "id",
  messages: {
    id,
    en,
  },
});

export default i18n;
