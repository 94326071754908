import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
import alerts from "@/components/alerts/alert";
// import router from "@/router";
import confirmations from "./confirmations";
import router from "@/router";
import loading from "./loading";
import { uuid } from "vue-uuid";

const questionDetail = {
  namespaced: true,
  state: {
    datas: {},
    details: [],
    mandatories: ["Ya", "Tidak"],
    queryParam: {},
    dialogFormBuilder: false,
    dialogFormView: false,
    question: {
      question_id: "",
      question: "",
      type: "",
      options: [],
      sequence: "",
      mandatory: "",
      action: "create",
      disabled: 0,
    },
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_DETAILS(state, details) {
      state.details = details;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
  },
  actions: {
    async postImage({ commit, state }, index) {
      const image = state.details.details[index].file;
      const uid = uuid.v1();
      const params = {
        agreement_no: "IMAGE",
        file_id: uid,
        file_name: uid + ".png",
        file_extention: "png",
        file: image,
        coordinate: "5098249866,4868769",
      };
      commit("SET_LOADING", true);
      return await AuthenticationService.postFormData(
        "sync/store/file",
        params
      ).then(({ data }) => {
        state.details.details[index].answer = data.data;
        commit("SET_LOADING", false);
      });
    },
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("backoffice/questions").then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get(
        "backoffice/questions/page?" + payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async getByQuestionId({ commit }, question_id) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.get(
        `backoffice/questions/${question_id}`
      ).then(({ data }) => {
        commit("SET_DETAILS", data.data);
        store.dispatch("loading/setLoading", false);
        commit("SET_LOADING", false);
      });
    },
    async allByGroup({ commit }, payload) {
      commit("SET_LOADING", true);
      return await AuthenticationService.get(
        `backoffice/questions/group/coloum/${payload}`
      ).then(({ data }) => {
        commit("SET_DETAILS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async submitForm({ commit }, payload) {
      // console.log(JSON.stringify(payload));
      // commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      // return await AuthenticationService.get("backoffice/questions").then(
      //   ({ data }) => {
      //     commit("SET_DATAS", data.data);
      //     commit("SET_LOADING", false);
      //   }
      // );
    },
    closeFormBuilder({ state }, payload) {
      state.dialogFormBuilder = false;
    },
    async viewForm({ state }, payload) {
      state.dialogFormView = true;
    },
    async onAction({ state }, payload) {
      if (payload[1] === "Detail") {
        router.push({
          name: "QuestionsDetail",
          query: { question_id: payload[0]["question_id"] },
        });
      }
    },
    async add({ state }, payload) {
      state.dialogFormBuilder = true;
      state.question = {
        action: "Add",
      };
    },
    async hapus({ state }, payload) {
      state.question = {
        question_id: payload.question_id,
        parent: payload.parent,
        action: "Delete",
      };
      confirmations.confirmationForm(
        state.question,
        `questionDetail/${state.question.action}`
      );
    },
    addOption({ state }, payload) {
      state.question.options.push(payload);
    },
    async edit({ state }, payload) {
      // alert(JSON.stringify(payload));
      state.question = payload;
      state.question = {
        question_id: payload.question_id,
        question: payload.question,
        type: payload.type,
        options: [],
        sequence: payload.sequence.toString(),
        mandatory: payload.mandatory,
        action: "Update",
        disabled: payload.disabled,
      };
      if (payload.options !== null || payload.options !== "") {
        state.question.options = JSON.parse(payload.options);
      }
      state.dialogFormBuilder = true;
    },
    saveForm({ state }, payload) {
      payload.question.group = payload.details.group;
      payload.question.parent = payload.details.question_id;
      confirmations.confirmationForm(
        payload.question,
        `questionDetail/${payload.question.action}`
      );
    },
    async Add({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.post(
        "backoffice/questions/detail",
        payload
      )
        .then(({ data }) => {
          commit("SET_LOADING", false);
          state.dialogFormBuilder = false;
          store.dispatch("loading/setLoading", false);
          store.dispatch("questionDetail/getByQuestionId", payload.parent);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },
    async Update({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.put(
        `backoffice/questions/detail/${state.question.question_id}`,
        payload
      )
        .then(({ data }) => {
          store.dispatch("loading/setLoading", false);
          state.dialogFormBuilder = false;
          alerts.sweetalertToast("success", data.message);
          store.dispatch("questionDetail/getByQuestionId", payload.parent);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },
    async Delete({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.delete(
        `backoffice/questions/${state.question.question_id}`,
        state.question
      )
        .then(({ data }) => {
          console.log(JSON.stringify(data));
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          store.dispatch(
            "questionDetail/getByQuestionId",
            state.question.parent
          );
          alerts.sweetalertToast("success", data.message);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },

    Question({ state }, payload) {
      alert("OK");
      state.dialogFormBuilder = true;
    },
  },
};

export default questionDetail;
