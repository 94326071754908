import AuthenticationService from "@/services/AuthenticationService";
// import store from "@/store";
// import alerts from "@/components/alerts/alert";
// import router from "@/router";

const tahun_ajaran = {
  namespaced: true,
  state: {
    all: [],
    datas: [],
    queryParam: {},
    loading: false,
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_ALL(state, datas) {
      state.all = datas;
    },
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
  },
  actions: {
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get(
        "tahun-ajaran/page?" + payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("tahun-ajaran").then(
        ({ data }) => {
          commit("SET_ALL", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
  },
};

export default tahun_ajaran;
