import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
// import loading from "./loading";
import alerts from "@/components/alerts/alert";
// import router from "@/router";

const register = {
  namespaced: true,
  state: {
    datas: {},
    queryParam: {},
    loading: false,
    modalCreate: false,
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
    SET_MODAL_CREATE(state, data) {
      state.modalCreate = data;
    },
  },
  actions: {
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("register/page?" + payload).then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    onRefresh({ state }) {
      store.dispatch("register/paginate", state.queryParam);
    },
    onAction({ commit }, payload) {
      commit("SET_MODAL_CREATE", true);
    },
    save({ state }, payload) {
      console.log(JSON.stringify(payload));
      alerts.sweetalertToast("error", "Maaf... belum bisa di gunakan");
    },
    async fileSave({ commit, state }, payload) {
      console.log(JSON.stringify(payload));
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.postFormData(
        "sync/store/file",
        payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
  },
};

export default register;
