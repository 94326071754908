import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
import alerts from "@/components/alerts/alert";
// import router from "@/router";
import confirmations from "./confirmations";
import { uuid } from "vue-uuid";
import router from "@/router";

const teacher_subjects = {
  namespaced: true,
  state: {
    all: [],
    datas: {},
    form: {},
    queryParam: {},
    loading: false,
    modalCreate: false,
    detail: null,
    modalDetail: false,
    modalTeacherSubject: false,
    paramsSubjectTeacher: null,
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_ALL(state, datas) {
      state.all = datas;
    },
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
    SET_MODAL_CREATE(state, data) {
      state.modalCreate = data;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
    SET_MODAL_DETAIL(state, data) {
      state.modalDetail = data;
    },
  },
  actions: {
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("teachers/subjects/all").then(
        ({ data }) => {
          commit("SET_ALL", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    onRefresh({ state }) {
      store.dispatch("teacher_subjects/paginate", state.queryParam);
    },
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get(
        "teachers/subjects/page?" + payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    onAction({ commit }, payload) {
      if (payload[1] === "create") {
        commit("SET_MODAL_CREATE", true);
      } else if (payload[1] === "Detail") {
        console.log(JSON.stringify(payload[0]));
        commit("SET_DETAIL", payload[0]);
        commit("SET_MODAL_DETAIL", true);
      }
    },
    confirmation({ commit }, payload) {
      confirmations.confirmationForm(
        payload[0],
        `teacher_subjects/${payload[1]}`
      );
    },
    async create({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      // console.log(JSON.stringify(state.form));
      // const params = {
      //   teacher_id: state.form.teacher_id,
      //   subject_id: state.form.subject_id,
      // };
      return await AuthenticationService.post("teachers/subjects", state.form)
        .then(({ data }) => {
          alerts.sweetalertToast("success", data.message);
          commit("SET_LOADING", false);
          commit("SET_MODAL_CREATE", false);
          state.form.subject_id = null;
          store.dispatch("loading/setLoading", false);
          store.dispatch("teacher_subjects/onRefresh");
          store.dispatch("teachers/onRefresh");
        })
        .catch((error) => {
          // console.log(JSON.stringify(error));
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.message);
        });
    },
  },
};

export default teacher_subjects;
