<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="blue">
              <img :src="user.avatar" alt="John" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.name }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn :class="fav ? 'red--text' : ''" icon @click="fav = !fav">
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="ml-auto mr-auto"
            color="error"
            small
            rounded
            @click="logout"
          >
            {{ $t("button.Logout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "ModalAccount",
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    selectedItem: 0,
  }),
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    items() {
      return [
        { text: this.$t("title.Profile"), icon: "mdi-account" },
        { text: this.$t("title.Setting"), icon: "mdi-cogs" },
        // { text: "Starred", icon: "mdi-star" },
        // { text: "Recent", icon: "mdi-history" },
        // { text: "Offline", icon: "mdi-check-circle" },
        // { text: "Uploads", icon: "mdi-upload" },
        // { text: "Backups", icon: "mdi-cloud-upload" },
      ];
    },
  },
  methods: {
    logout() {
      this.menu = false;
      store.dispatch("auth/LogOut");
    },
  },
};
</script>
