import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
// import loading from "./loading";
import alerts from "@/components/alerts/alert";
import confirmations from "./confirmations";
// import router from "@/router";

const students = {
  namespaced: true,
  state: {
    all: [],
    datas: {},
    queryParam: {},
    loading: false,
    modalCreate: false,
    detail: null,
    modalDetail: false,
    activationData: null,
    activationModal: false,
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_ACTIVATION_DATA(state, data) {
      state.activationData = data;
    },
    SET_ACTIVATION_MODAL(state, data) {
      state.activationModal = data;
    },
    SET_ALL(state, datas) {
      state.all = datas;
    },
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
    SET_MODAL_CREATE(state, data) {
      state.modalCreate = data;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
    SET_MODAL_DETAIL(state, data) {
      state.modalDetail = data;
    },
  },
  actions: {
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("students").then(({ data }) => {
        commit("SET_ALL", data.data);
        commit("SET_LOADING", false);
      });
    },
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("students/page?" + payload).then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    async myStudentPaginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get(
        "students/my-student/page?" + payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    onRefresh({ state }) {
      store.dispatch("students/paginate", state.queryParam);
    },
    onAction({ commit }, payload) {
      if (payload[1] === "create") {
        commit("SET_MODAL_CREATE", true);
      } else if (payload[1] === "Detail") {
        // console.log(JSON.stringify(payload[0]));
        commit("SET_DETAIL", payload[0]);
        commit("SET_MODAL_DETAIL", true);
      }
    },
    confirmation({ commit }, payload) {
      confirmations.confirmationForm(payload[0], `students/${payload[1]}`);
    },
    async create({ commit, state }, payload) {
      commit("SET_LOADING", true);
      const params = {
        questions: payload.details,
        json_detail: JSON.stringify(payload),
      };
      store.dispatch("loading/setLoading", true);
      // console.log(JSON.stringify(params));
      return await AuthenticationService.post("students", params)
        .then(({ data }) => {
          alerts.sweetalertToast("success", data.message);
          commit("SET_LOADING", false);
          commit("SET_MODAL_CREATE", false);
          store.dispatch("loading/setLoading", false);
          store.dispatch("students/onRefresh");
        })
        .catch((error) => {
          // console.log(JSON.stringify(error));
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.message);
        });
    },
    save({ state }, payload) {
      console.log(JSON.stringify(payload));
      alerts.sweetalertToast("error", "Maaf... belum bisa di gunakan");
    },
    async fileSave({ commit, state }, payload) {
      console.log(JSON.stringify(payload));
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.postFormData(
        "sync/store/file",
        payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    activation({ commit }, payload) {
      commit("SET_ACTIVATION_MODAL", true);
      commit("SET_ACTIVATION_DATA", payload);
    },
    async prosesActivation({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.put(
        `students/${payload.items.student_id}/activation`,
        payload
      )
        .then(({ data }) => {
          alerts.sweetalertToast("success", data.message);
          commit("SET_LOADING", false);
          commit("SET_MODAL_CREATE", false);
          commit("SET_ACTIVATION_MODAL", false);
          store.dispatch("loading/setLoading", false);
          store.dispatch("students/onRefresh");
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.message);
        });
    },
  },
};

export default students;
