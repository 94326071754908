<template>
  <v-dialog v-model="loading" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{ $t("title.PleaseWait") }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LoadingPage",
  data() {
    return {};
  },
  computed: {
    ...mapState("loading", {
      loading: (state) => state.loading,
    }),
  },
};
</script>
