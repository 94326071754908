import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import auth from "./modules/auth";
import loading from "./modules/loading";
import users from "./modules/users";
import sidebar from "./modules/sidebar";
import roles from "./modules/roles";
import menus from "./modules/menus";
import rules from "./modules/rules";
import access_menus from "./modules/access_menus";
import personalities from "./modules/personalities";
import scores from "./modules/scores";
import classes from "./modules/classes";
import aspect from "./modules/aspect";
import students from "./modules/students";
import teachers from "./modules/teachers";
import income from "./modules/income";
import fee_type from "./modules/fee_type";
import fee from "./modules/fee";
import payment from "./modules/payment";
import expense_type from "./modules/expense_type";
import expense from "./modules/expense";
import transactions from "./modules/transactions";
import questions from "./modules/questions";
import questionDetail from "./modules/questionDetail";
import rooms from "./modules/rooms";
import register from "./modules/register";
import staff from "./modules/staff";
import store_file from "./modules/store_file";
import subjects from "./modules/subjects";
import teacher_subjects from "./modules/teacher_subjects";
import tahun_ajaran from "./modules/tahun_ajaran";
import teacher_classes from "./modules/teacher_classes";
import enrollment_student_classes from "./modules/enrollment_student_classes";
import enrollment_teacher_classes from "./modules/enrollment_teacher_classes";

Vue.use(Vuex);

const secureLS = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "vue-auth-persist",
});

const authState = createPersistedState({
  key: "auth-persist-state",
  paths: ["auth.captcha"],
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key),
  },
});

const sidebarState = createPersistedState({
  key: "sidebar-persist-state",
  paths: ["sidebar.sidebar"],
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key),
  },
});

const tokenState = createPersistedState({
  key: "token-persist-state",
  paths: ["auth.token"],
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key),
  },
});

const profileState = createPersistedState({
  key: "profile-persist-state",
  paths: ["auth.user"],
  storage: {
    getItem: (key) => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: (key) => secureLS.remove(key),
  },
});

export default new Vuex.Store({
  modules: {
    auth: auth,
    loading: loading,
    users: users,
    sidebar: sidebar,
    roles: roles,
    menus: menus,
    rules: rules,
    access_menus: access_menus,
    personalities: personalities,
    scores: scores,
    classes: classes,
    aspect: aspect,
    students: students,
    teachers: teachers,
    income: income,
    fee_type: fee_type,
    fee: fee,
    payment: payment,
    expense_type: expense_type,
    expense: expense,
    transactions: transactions,
    questions: questions,
    rooms: rooms,
    register: register,
    staff: staff,
    questionDetail: questionDetail,
    store_file: store_file,
    subjects: subjects,
    teacher_subjects: teacher_subjects,
    tahun_ajaran: tahun_ajaran,
    teacher_classes: teacher_classes,
    enrollment_student_classes: enrollment_student_classes,
    enrollment_teacher_classes: enrollment_teacher_classes,
  },
  plugins: [authState, sidebarState, tokenState, profileState],
});
