import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
import alerts from "@/components/alerts/alert";
// import router from "@/router";
import confirmations from "./confirmations";
import router from "@/router";
import loading from "./loading";

const questions = {
  namespaced: true,
  state: {
    datas: {},
    details: [],
    mandatories: ["Ya", "Tidak"],
    queryParam: {},
    dialogFormBuilder: false,
    dialogFormView: false,
    question: {
      question_id: "",
      question: "",
      type: "",
      options: [],
      sequence: "",
      mandatory: "",
      action: "create",
      disabled: false,
    },
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_DETAILS(state, details) {
      state.details = details;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
  },
  actions: {
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("backoffice/questions").then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get(
        "backoffice/questions/page?" + payload
      ).then(({ data }) => {
        commit("SET_DATAS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async getByQuestionId({ commit }, question_id) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.get(
        `backoffice/questions/${question_id}`
      ).then(({ data }) => {
        commit("SET_DETAILS", data.data);
        store.dispatch("loading/setLoading", false);
        commit("SET_LOADING", false);
      });
    },
    async allByGroup({ commit }, payload) {
      commit("SET_LOADING", true);
      return await AuthenticationService.get(
        `backoffice/questions/form/${payload}`
      ).then(({ data }) => {
        commit("SET_DETAILS", data.data);
        commit("SET_LOADING", false);
      });
    },
    async submitForm({ commit }, payload) {
      // console.log(JSON.stringify(payload));
      // commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      // return await AuthenticationService.get("backoffice/questions").then(
      //   ({ data }) => {
      //     commit("SET_DATAS", data.data);
      //     commit("SET_LOADING", false);
      //   }
      // );
    },
    async viewForm({ state }, payload) {
      state.dialogFormView = true;
    },
    async onAction({ state }, payload) {
      if (payload[1] === "Detail") {
        router.push({
          name: "QuestionsDetail",
          query: { question_id: payload[0]["question_id"] },
        });
      }
    },
    async add({ state }, payload) {
      state.dialogFormBuilder = true;
      state.question = {
        action: "Add",
      };
    },
    async hapus({ state }, payload) {
      state.question = {
        question_id: payload.question_id,
        action: "Delete",
      };
      store.dispatch("questions/saveForm");
    },
    addOption({ state }, payload) {
      state.question.options.push(payload);
    },
    async edit({ state }, payload) {
      // alert(JSON.stringify(payload));
      state.question = payload;
      state.question = {
        question_id: payload.question_id,
        question: payload.question,
        type: payload.type,
        options: [],
        sequence: payload.sequence.toString(),
        mandatory: payload.mandatory,
        action: "Update",
        disabled: payload.disabled,
      };
      if (payload.options !== null || payload.options !== "") {
        state.question.options = JSON.parse(payload.options);
      }
      state.dialogFormBuilder = true;
    },
    saveForm({ state }) {
      state.question["group"] = state.details.group;
      state.question["parent"] = state.details.question_id;
      confirmations.confirmationForm(
        state.question,
        `questionDetail/${state.question.action}`
      );
    },
    async Save({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.post(
        "backoffice/questions",
        state.question
      )
        .then(({ data }) => {
          commit("SET_LOADING", false);
          state.dialogFormBuilder = false;
          store.dispatch("loading/setLoading", false);
          store.dispatch("questions/all");
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },
    async update({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.put(
        `backoffice/questions/${state.question.question_id}`,
        state.question
      )
        .then(({ data }) => {
          console.log(JSON.stringify(data));
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          state.dialogFormBuilder = false;
          alerts.sweetalertToast("success", data.message);
          store.dispatch("questions/all");
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },
    async delete({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.delete(
        `backoffice/questions/${state.question.question_id}`,
        state.question
      )
        .then(({ data }) => {
          console.log(JSON.stringify(data));
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          store.dispatch("questions/all");
          alerts.sweetalertToast("success", data.message);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.error);
        });
    },

    Question({ state }, payload) {
      alert("OK");
      state.dialogFormBuilder = true;
    },
  },
};

export default questions;
