import AuthenticationService from "@/services/AuthenticationService";
import store from "@/store";
import confirmations from "./confirmations";
import alerts from "@/components/alerts/alert";

const classes = {
  namespaced: true,
  state: {
    all: [],
    datas: {},
    queryParam: {},
    modalCreate: false,
  },
  getters: {
    getDatas(state) {
      return state.datas;
    },
  },
  mutations: {
    SET_ALL(state, datas) {
      state.all = datas;
    },
    SET_DATAS(state, datas) {
      state.datas = datas;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_QUERY_PARAM(state, data) {
      state.queryParam = data;
    },
    SET_MODAL_CREATE(state, data) {
      state.modalCreate = data;
    },
  },
  actions: {
    async all({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("classes").then(({ data }) => {
        commit("SET_ALL", data.data);
        commit("SET_LOADING", false);
      });
    },
    async paginate({ commit }, payload) {
      commit("SET_LOADING", true);
      commit("SET_QUERY_PARAM", payload);
      return await AuthenticationService.get("classes/page?" + payload).then(
        ({ data }) => {
          commit("SET_DATAS", data.data);
          commit("SET_LOADING", false);
        }
      );
    },
    onRefresh({ state }) {
      store.dispatch("classes/paginate", state.queryParam);
    },
    onAction({ commit }, payload) {
      if (payload[1] === "create") {
        commit("SET_MODAL_CREATE", true);
      } else if (payload[1] === "Detail") {
        commit("SET_DETAIL", payload[0]);
        commit("SET_MODAL_DETAIL", true);
      }
    },
    confirmation({ commit }, payload) {
      confirmations.confirmationForm(payload[0], `classes/${payload[1]}`);
    },
    async create({ commit, state }, payload) {
      commit("SET_LOADING", true);
      store.dispatch("loading/setLoading", true);
      return await AuthenticationService.post("classes", payload)
        .then(({ data }) => {
          alerts.sweetalertToast("success", data.message);
          commit("SET_LOADING", false);
          commit("SET_MODAL_CREATE", false);
          store.dispatch("loading/setLoading", false);
          store.dispatch("classes/onRefresh");
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          store.dispatch("loading/setLoading", false);
          alerts.sweetalertToast("error", error.response.data.message);
        });
    },
  },
};

export default classes;
