import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    // options: { minifyTheme },
    themes: {
      light: {
        primary: "#0d5b99",
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        warnaNavbar: "#ffffff",
        homeColor: "#F5FCFF",
        textName: "#1867c0",
        bgAbout: "#ffffff",
        bgTwo: "#F6F4FC",
        bgSidebar: "#0d5b99",
        menuSidebar: "#0d5b99",
        menuWhite: "#FFFFFF",
        textPrimary: "#602A7E",
      },
      dark: {
        primary: "#0d5b99",
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        warnaNavbar: "#121212",
        homeColor: "#15002a",
        textName: "#ffffff",
        bgAbout: "#121212",
        bgTwo: "#181818",
        menuSidebar: "#0d5b99",
        menuWhite: "#FFFFFF",
        textPrimary: "#FFFFFF",
      },
    },
  },
});
